import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { Navbar, Nav } from "react-bootstrap"

const NavMenu = ({ pageInfo }) => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
        sticky="top"
        className="shadow-sm"
      >
        <Navbar.Brand className="ms-2">
          <Link to="/">
            <StaticImage
              src="../assets/images/logo.svg"
              alt="azszero"
              placeholder="tracedSVG"
              layout="constrained"
              width={200}
            ></StaticImage>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav as="ul" className="ms-auto me-5 fs-4">
            <Nav.Item as="li" className="ms-2">
              <Link
                to="test"
                className="nav-link nav-menue"
                activeClassName="active"
              >
                test
              </Link>
            </Nav.Item>
            <Nav.Item as="li" className="ms-2">
              <Link
                to="test"
                className="nav-link nav-menue"
                activeClassName="active"
              >
                test
              </Link>
            </Nav.Item>
            <Nav.Item as="li" className="ms-2">
              <Link
                to="test"
                className="nav-link nav-menue"
                activeClassName="active"
              >
                Login/Register
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

// const NavStyled = styled.Navbar`
//   z-index: 9999;
// `
export default NavMenu
